import css from "styled-jsx/css";

const minContent = 560; // due to youtube
const maxContent = 780; // just made it up (smaller than ign,  more than thegetaway)
const sidebar = 300; // used to be 360 from ign, but now we do 300 to line up with most common ad formats
export const padding0 = 18;
export const padding1 = 36;
export const breakpoint0 = minContent;
export const breakpoint1 = padding1 + minContent + padding1 + sidebar + padding1;
export const maxSection1 = padding0 + maxContent + padding0;
export const breakpoint2 = padding1 + maxContent + padding1 + sidebar + padding1;
export const maxSection2 = padding1 + maxContent + padding1 + sidebar + padding1;

export const tinyFont = ".9rem";
export const smallFont = "1.3rem";
export const mediumFont = "1.5rem";
export const largeFont = "2rem";
export const xLargeFont = "3.5rem";
export const xxLargeFont = "4rem";

export const resetStyle = css.global`
  html {
    box-sizing: border-box;
    font-size: 16px;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  body,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  ol,
  ul {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

ol {
  list-style-type: decimal;
  padding-left: 5px;
}
ul {
  list-style-type: none;
}

  img {
    max-width: 100%;
    height: auto;
  }
  body {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif,
      "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  }
  .page {
    text-align: left;
  }

  hr {
    color: #eaeaea;
    border-top: 1px solid #eaeaea;
    margin: 1.5rem 0;
  }
`;

export const pageStyle = css`
  .section {
    padding: 0 ${padding0}px;
    max-width: ${maxSection1}px;
    margin: 0 auto;
  }
  .page-content {
    text-align: left;
  }
  .sidebar-container {
    display: none;
  }
  @media screen and (min-width: ${breakpoint1}px) {
    .section {
      padding: 0 ${padding1}px;
      max-width: ${maxSection2}px;
    }
    .two-column {
      display: grid;
      grid-template-columns: auto ${sidebar}px;
      grid-gap: ${padding1}px;
    }
    .sidebar-container {
      display: block;
    }
  }
`;
