import { useState, useEffect } from "react";

const MOBILE_SIZE = 576;

export const useLocalStorageState = (key, initialValue) => {
  const [state, setState] = useState(() => {
    try {
      const entry = window.localStorage.getItem(key);
      return entry ? JSON.parse(entry) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });
  const storeValue = (value) => {
    setState(value);
    window.localStorage.setItem(key, JSON.stringify(value));
  };
  return [state, storeValue];
};

export const useIsMobile = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const onResize = () => {
      const newValue = window.innerWidth < MOBILE_SIZE;
      setIsMobile(newValue);
    };
    window.addEventListener("resize", onResize);
    onResize();

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return isMobile;
};
