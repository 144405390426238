import { useState, useContext } from "react";
import { ArrowDownIcon, ArrowUpIcon } from "../utils/icons";
import { MENU_LINKS } from "../data";
import { Context } from "../context";

const SubMenu = ({ title, subtitle, links, viewAllLink }) => {
  const [open, setOpen] = useState(false);
  function toggleOpen() {
    setOpen(!open);
  }
  const { theme } = useContext(Context);

  return (
    <div className="sub-menu">
      <style jsx>
        {`
          .sub-menu {
            width: 100%;
          }
          .header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 2rem;
            border-bottom: 1px solid ${theme.borderColor};
            margin: 0;
          }
          .header :global(.icon) {
            fill: ${theme.primaryIconColor};
          }
          .title {
            display: none;
            height: 2rem;
            text-align: center;
            color: ${theme.primaryTextColor};
            font-weight: 700;
            font-size: 1.5rem;
            line-height: 2rem;
            margin: 0;
          }
          .mobile-title {
            display: block;
          }
          .links {
            display: none;
            flex-direction: column;
            vertical-align: middle;
            margin: 0;
            padding: 0;
          }
          .links li {
            padding: 0;
            margin: 0;
          }
          .links a {
            width: 100%;
            display: block;
            font-size: 1.3rem;
            color: ${theme.secondaryTextColor};
            line-height: 2rem;
            text-decoration: none;
            padding: 1rem 2rem;
          }
          .links a.subtitle {
            display: none;
            color: ${theme.primaryTextColor};
            font-weight: 700;
            font-size: 1.35rem;
            margin: 0;
          }
          .links a:hover {
            color: ${theme.primaryTextColor};
          }
          .links a.view-all {
            text-decoration: underline;
          }
          @media screen and (min-width: 800px) {
            .sub-menu :global(.icon) {
              display: none;
            }
            .header {
              border: none;
            }
            .sub-menu {
              max-width: 20rem;
            }
            .title {
              display: block;
            }
            .mobile-title {
              display: none;
            }
            .links a.subtitle {
              display: block;
            }
          }
        `}
      </style>
      <style jsx>
        {`
          .links {
            display: ${open ? "flex" : "none"};
            border-bottom: ${open ? `1px solid ${theme.borderColor}` : "none"};
          }
          @media screen and (min-width: 800px) {
            .links {
              display: flex;
            }
          }
        `}
      </style>
      <div className="header" onClick={toggleOpen}>
        <h3 className="title">{title}</h3>
        <h3 className="title mobile-title">{subtitle ? subtitle : title}</h3>
        {open ? <ArrowUpIcon /> : <ArrowDownIcon />}
      </div>
      <ul className="links">
        {subtitle && (
          <li>
            <a className="subtitle">{subtitle}</a>
          </li>
        )}
        {links.map((link, index) => (
          <li key={`${link.url}-${index}`}>
            <a href={link.url}>{link.name}</a>
          </li>
        ))}
        {viewAllLink && (
          <li>
            <a href={viewAllLink} className="view-all">View All</a>
          </li>
        )}
      </ul>
    </div>
  );
};

const Menu = () => {
  const { theme, menu } = useContext(Context);
  const links = MENU_LINKS.map((link) => ({
    ...link,
    url: `/${link.slug}`,
  }));
  const platforms = menu.platforms.slice(0, 10).map((platform) => ({
    name: platform.name,
    url: `/platform/${platform.slug}`,
  }));
  const gameCategories = menu.categories.slice(0, 10).map((category) => ({
    name: category.name,
    url: `/game/category/${category.slug}`,
  }));
  const appCategories = menu.app_categories.slice(0, 10).map((category) => ({
    name: category.name,
    url: `/app/category/${category.slug}`,
  }));

  return (
    <div className="menu">
      <style jsx>{`
        .menu {
          position: relative;
          background: ${theme.backgroundColor};
          display: flex;
          flex-direction: column;
          user-select: none;
          border-bottom: 1px solid ${theme.borderColor};
        }
        @media screen and (min-width: 800px) {
          .menu {
            flex-direction: row;
            justify-content: center;
          }
        }
      `}</style>
      <SubMenu key="Platforms" title="Platforms" links={platforms} />
      <SubMenu
        key="App Categories"
        title="Categories"
        subtitle="App Categories"
        links={appCategories}
        viewAllLink="/app/category/all"
      />
      <SubMenu
        key="Game Categories"
        title=""
        subtitle="Game Categories"
        links={gameCategories}
        viewAllLink="/game/category/all"
      />
      <SubMenu key="About" title="About" links={links} />
    </div>
  );
};

export default Menu;
