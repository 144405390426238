import { useState, useRef, useEffect, useContext } from "react";
import Autosuggest from "react-autosuggest";

import { Context } from "../context";
import { SearchIcon } from "../utils/icons";
import { getSearch } from "../utils/api";
import { cloudflareImage } from "../utils/utils";
import { smallFont, tinyFont } from "../utils/styles";

const autosuggestTheme = (theme) => ({
  container: {
    position: "relative",
    width: "100%",
  },
  input: {
    backgroundColor: theme.backgroundColor,
    border: `1px solid ${theme.borderColor}`,
    color: theme.secondaryTextColor,
  },
  inputFocused: {
    outline: "none",
  },
  inputOpen: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  suggestionsContainer: {
    display: "none",
  },
  suggestionsContainerOpen: {
    display: "block",
    position: "absolute",
    width: "100%",
    borderRight: `1px solid ${theme.borderColor}`,
    borderLeft: `1px solid ${theme.borderColor}`,
    backgroundColor: theme.backgroundColor,
    fontWeight: 300,
    zIndex: 200,
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: "none",
    color: theme.primaryTextColor,
    fontSize: "1rem",
  },
  suggestion: {
    cursor: "pointer",
    borderBottom: `1px solid ${theme.borderColor}`,
    padding: ".25rem",
  },
  suggestionHighlighted: {
    backgroundColor: theme.selectedBackgroundColor,
  },
});

export default function SearchBox({ id, onActive, onInactive, focus }) {
  let q = "";
  if (typeof window !== "undefined") {
    const url = new URL(window.location.href);
    q = url.searchParams.get("q") || "";
  }
  const { theme } = useContext(Context);
  const [value, setValue] = useState(q);
  const [suggestions, setSuggestions] = useState([]);
  const formRef = useRef(null);
  const inputRef = useRef(null);

  function onChange(event, { newValue }) {
    setValue(newValue);
    if (newValue) {
      onActive();
    } else {
      onInactive();
    }
  }

  useEffect(() => {
    if (inputRef.current && inputRef.current.input && focus) {
      inputRef.current.input.focus();
    }
  });

  function onSuggestionsFetchRequested({ value }) {
    getSearch({ q: value }).then(({ results }) => {
      results = results.map((result) => ({
        ...result,
        image: cloudflareImage(result.image, { fit: "cover", width: 80, height: 80 }),
        url: `/${result.type}/${result.slug}`,
      }));
      setSuggestions(results);
    });
  }

  function getSuggestionValue(suggestion) {
    return suggestion.name;
  }

  function renderSuggestion(suggestion) {
    return (
      <div className="autosuggest-option">
        <style jsx>{`
          .autosuggest-option {
            display: flex;
            align-items: center;
            position: relative;
          }
          .image {
            width: 80px;
            height: 80px;
            border-radius: 6px;
          }
          .text {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            height: 100%;
          }
          .name {
            font-size: ${smallFont};
            padding: 0.7rem 1rem;
            color: ${theme.secondaryTextColor};
          }
          .category {
            font-size: ${tinyFont};
            padding: 0.4rem 1rem;
          }
        `}</style>
        <img className="image" src={suggestion.image} alt="" />
        <div className="text">
          <span className="name">{suggestion.name}</span>
          <span className="category">{suggestion.category}</span>
        </div>
      </div>
    );
  }

  function onSuggestionsClearRequested() {
    setSuggestions([]);
  }

  function onSuggestionSelected(event, data) {
    event.preventDefault();
    window.location = data.suggestion.url;
  }

  function handleMouseDownSearch(event) {
    if (formRef.current && value && value.trim()) {
      formRef.current.submit();
    }
  }

  const inputProps = {
    placeholder: "Search...",
    name: "q",
    className: "search-input",
    value,
    onChange,
    onFocus: onActive,
    onBlur: onInactive,
  };
  return (
    <div className="search-container">
      <style jsx>
        {`
          .search-container {
            position: relative;
            background-color: red;
            display: flex;
            align-items: center;
            height: 3rem;
            width: 100%;
            z-index: 3;
          }
          .search-container :global(.search-input) {
            width: 100%;
            font-size: 2rem;
            padding: 0.5rem;
            border: 1px solid #eee;
            border-radius: 6px;
            color: #666;
            outline: none;
            box-shadow: none;
            padding: 1rem 3.5rem 1rem 1rem;
          }

          .search-container :global(.search-icon) {
            position: absolute;
            z-index: 4;
            right: 1rem;
            top: 0.5rem;
            width: 2rem;
            height: 2rem;
            fill: ${theme.primaryIconColor};
          }
          .search-container form {
            width: 100%;
          }
        `}
      </style>
      <form method="get" action="/search" ref={formRef}>
        <Autosuggest
          id={id}
          suggestions={suggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={inputProps}
          theme={autosuggestTheme(theme)}
          highlightFirstSuggestion={false}
          onSuggestionSelected={onSuggestionSelected}
          ref={inputRef}
        />
        <SearchIcon onMouseDown={handleMouseDownSearch} />
      </form>
    </div>
  );
}
