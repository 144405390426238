import { useState, useEffect, useContext } from "react";
import { connect } from "react-redux";

import { signOut, signInByPopup } from "../redux/user";
import { HamburgerIcon, SearchIcon, XIcon, UserIcon } from "../utils/icons";
import Logo from "./Logo";
import Search from "./Search";
import Menu from "./Menu";
import SocialLinks from "./SocialLinks";
import { Context } from "../context";

const ESCAPE = 27;

const Navbar = ({ signedIn, dispatch }) => {
  const { theme } = useContext(Context);

  const DEFAULT = "default";
  const SEARCH_OPEN = "search-open";
  const MENU_OPEN = "menu-open";
  const [state, setState] = useState(DEFAULT);
  const [searching, setSearching] = useState();

  function onKeyDown(event) {
    if (event.keyCode === ESCAPE) {
      setState(DEFAULT);
    }
  }
  useEffect(() => {
    document.addEventListener("keydown", onKeyDown, false);
    return () => {
      document.removeEventListener("keydown", onKeyDown, false);
    };
  }, []);
  function toggleMenu() {
    if (state === MENU_OPEN) {
      setState(DEFAULT);
    } else {
      setState(MENU_OPEN);
    }
  }
  function toggleSearch() {
    if (state === SEARCH_OPEN) {
      setState(DEFAULT);
    } else {
      setState(SEARCH_OPEN);
    }
    setSearching(!searching)
  }
  function handleClickAccount(event) {
    if (!signedIn) {
      dispatch(signInByPopup());
    } else {
      dispatch(signOut());
    }
  }

  return (
    <header>
      <style jsx>{`
        .header {
          position: relative;
          background: ${theme.backgroundColor};
          display: flex;

          align-items: center;
          justify-content: space-between;
          height: 4rem;
          padding: 1rem;
          border-bottom: 1px solid ${theme.borderColor};
          z-index: 2;
        }
        .header :global(.icon) {
          fill: ${theme.primaryIconColor};
          cursor: pointer;
          width: 2rem;
          height: 2rem;
        }
        .header .logo-container {
          display: flex;
          align-items: center;
          margin: 0 1.5rem;
          max-width: 180px;
        }
        .header .logo-container :global(.logo) {
          width: 100%;
        }
        .mobile-header {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
        }
        .search-header {
          display: none;
        }
        .social-header {
          display: none;
        }
        .user-header {
          display: none;
        }
        .account {
          cursor: pointer;
          margin: 0 1rem;
        }

        .account .placeholder {
          opacity: 0;
        }
        .account {
          position: relative;
        }
        .account .actual {
          position: absolute;
          left: 0;
          z-index: 1;
        }
        .overlay {
          position: fixed;
          z-index: 1;
          left: 0;
          right: 0;
          bottom: 0;
          background: rgba(0, 0, 0, 0.2);
          top: 0;
        }
        @media screen and (min-width: 1000px) {
          .header {
            padding: 2.5rem;
            height: 6rem;
          }
          .overlay {
            top: 0;
          }
          .search-header {
            display: block;
            flex-grow: 1;

            max-width: 40rem;
          }
          .social-header {
            display: block;
          }

          .mobile-header {
            justify-content: left;
            width: auto;
          }
          .mobile-header :global(.search-icon) {
            display: none;
          }
        }
        .menu-modal {
          display: ${state === MENU_OPEN ? "block" : "none"};
          top: 4rem;
          left: 0;
          right: 0;
          z-index: 4;
        }
        .search-modal {
          display: ${state === SEARCH_OPEN ? "block" : "none"};
          padding: 2rem;
          background-color: ${theme.backgroundColor};
          z-index: 4;

          top: 4rem;
          left: 0;
          right: 0;
        }
      `}</style>
      <div className="header">
        <div className="mobile-header">
          {state === MENU_OPEN ? (
            <XIcon onClick={toggleMenu} />
          ) : (
            <HamburgerIcon onClick={toggleMenu} />
          )}
          <a className="logo-container" href="/">
            <Logo />
          </a>
          {state === SEARCH_OPEN ? (
            <XIcon onClick={toggleSearch} />
          ) : (
            <SearchIcon onClick={toggleSearch} />
          )}
        </div>

        <div className="search-header">
          <Search
            id="header-search"
            onActive={() => setSearching(true)}
            onInactive={() => setSearching(false)}
          />
        </div>
        <div className="account" onClick={handleClickAccount}>
          <span className="placeholder">Logout</span>
          <span className="actual">{signedIn ? "Logout" : "Login"}</span>
        </div>
        <div className="user-header">
          <UserIcon />
        </div>
        {/* <div className="social-header">
          <SocialLinks />
        </div> */}
      </div>

      <div className="modal menu-modal">
        <Menu />
      </div>
      <div className="modal search-modal search-container">
        <Search
          id="modal-search"
          focus={state === SEARCH_OPEN}
          onActive={() => setSearching(true)}
          onInactive={() => setSearching(false)}
        />
      </div>
      {searching && <div className="overlay" />}
    </header>
  );
};

const mapStateToProps = (state) => ({
  signedIn: state.user.signedIn,
});

export default connect(mapStateToProps)(Navbar);
