export const MENU_LINKS = [
  { name: "About", slug: "about", url: "/about" },
  { name: "Contact", slug: "contact", url: "/contact" },
  { name: "Privacy", slug: "privacy", url: "/privacy" },
  { name: "Terms", slug: "terms", url: "/terms" }
];

export const CATEGORY_LINKS = [
  { name: "Categories", slug: "categories", url: "/categories" },
  { name: "Platforms", slug: "platforms", url: "/platforms" },
  { name: "Articles", slug: "articles", url: "/articles" },
  { name: "Reviews", slug: "reviews", url: "/reviews" }
];
