import { useContext } from "react";

import NewsletterSignup from "./NewsletterSignup";
import Logo from "./Logo";
import SocialLinks from "./SocialLinks";
import { Context } from "../context";
import { themes } from "../utils/theme";

export const LEGAL_LINKS = [
  { name: "Privacy", slug: "privacy", url: "/privacy" },
  { name: "Terms", slug: "terms", url: "/terms" },
  { name: "Do Not Sell My Info", slug: "do-not-sell-my-info", url: "/do-not-sell-my-info" },
  { name: "DMCA", slug: "dmca", url: "/dmca" },
  { name: "Contact", slug: "contact", url: "/contact" },
];

export const MORE_LINKS = [
  { name: "App Categories", slug: "app-categories", url: "/app/category/all" },
  { name: "Game Categories", slug: "game-categories", url: "/game/category/all" },
  { name: "Platforms", slug: "platforms", url: "/platforms" },
  { name: "Articles", slug: "articles", url: "/articles" },
  // { name: "Reviews", slug: "reviews", url: "/reviews" },
  { name: "About", slug: "about", url: "/about" },
];

const Footer = () => {
  const { theme } = useContext(Context);
  return (
    <footer>
      <style jsx>{`
        footer {
          display: flex;
          flex-direction: column;
          align-items: center;
        }
        .logo-container {
          margin-top: 2rem;
        }
        .newsletter-container {
          margin-top: 2rem;
        }
        .social-container {
          margin-top: 2rem;
        }
        .social-container :global(.icon) {
          width: 3rem;
          height: 3rem;
        }
        .social-container h3 {
          text-align: center;
          font-size: 1.2rem;
          line-height: 100%;
          margin: 0 0 1rem 0;
        }
        .copyright-container {
          margin-top: 4rem;
          margin-bottom: 8rem;
          color: ${theme.secondaryTextColor};
        }
        .links-container {
          margin: 2rem 0 0 0;
          display: flex;
          justify-content: space-between;
          width: 50%;
          max-width: 22rem;
        }

        .links-container h3 {
          text-transform: uppercase;
          font-size: 0.9rem;
          line-height: 100%;
          font-weight: bold;
          padding: 1rem;
          margin: 0 0 1rem 0;
        }
        .links-container ul {
          margin: 0;
        }
        .links-container li {
          padding: 1rem;
          line-height: 100%;
          font-size: 1rem;
          margin: 0;
        }
        .links-container a {
          text-decoration: none;
          font-size: 1rem;
          color: ${theme.secondaryTextColor};
        }
        .links-container a:hover {
          text-decoration: underline;
          color: ${theme.primaryTextColor};
        }
        .theme-chooser {
          display: flex;
        }
        .theme {
          display: block;
          padding: 2rem;
          cursor: pointer;
          color: ${theme.primaryTextColor};
          text-decoration: none;
        }
        .theme:hover {
          color: ${theme.primaryTextColor};
          text-decoration: underline;
        }
        @media screen and (min-width: 800px) {
          footer {
          }
        }
      `}</style>
      <div className="logo-container">
        <Logo />
      </div>
      <div className="links-container">
        <div>
          <h3>Legal</h3>
          <ul>
            {LEGAL_LINKS.map((link) => (
              <li key={link.url}>
                <a href={link.url}>{link.name}</a>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h3>More</h3>
          <ul>
            {MORE_LINKS.map((link) => (
              <li key={link.url}>
                <a href={link.url}>{link.name}</a>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* <div className="social-container">
        <h3>Follow AppCheeta</h3>
        <SocialLinks />
      </div> */}
      {/* <div className="theme-chooser">
        {Object.keys(themes).map((key) => (
          <a className="theme" key={key} href={`?theme=${key}`}>
            {themes[key].name}
          </a>
        ))}
      </div> */}
      <div className="copyright-container">
        <span>©2023 AppCheeta. All rights reserved.</span>
      </div>
    </footer>
  );
};

export default Footer;
